import React from "react"
import styled from "@emotion/styled"

import Layout from "../components/layout"
import SEO from "../components/seo"

const StyledNotFoundPage = styled.div`
  min-height: 100vh;
  padding-top: 100px;
`

const NotFoundPage = ({ location }) => (
  <Layout location={location}>
    <SEO title="404: Not found" />
    <StyledNotFoundPage>
      <h1>404 NOT FOUND</h1>
    </StyledNotFoundPage>
  </Layout>
)

export default NotFoundPage
